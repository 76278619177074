@import '../styles/colors';
@import './layout';

.base-page-container {
  height: 100%;
  display: flex;
  justify-content: center;
  flex: 1;
  background-color: $bg-secondary;

  .page-content {
    overflow-y: auto;
    width: 100%;
    padding: calc(#{$top-bar-height} + #{$page-top-padding}) $page-side-padding $page-top-padding $page-side-padding;
    display: flex;
    flex-direction: column;
  }

  @include max-width-tablet {
    overflow: auto;
    height: unset;
  }

  @include max-width-mobile {
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;

    .page-content {
      padding-top: $page-top-padding;
      width: 100vw;
      height: 100%;
      padding-bottom: 50px;
    }
  }
}
