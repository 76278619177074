@import '../styles/colors.scss';

.separator {
  border-color: $border-secondary;
  border-style: solid;
  border-width: 0px;
  margin: 0;

  &.separator-horizontal {
    border-top-width: 1px;
    height: 0px;
    width: 100%;
  }

  &.separator-vertical {
    border-left-width: 1px;
    height: 100%;
    width: 0px;
  }
}
