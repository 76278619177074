@import '@grain/web-components/src/styles/colors';
@import '@grain/web-components/src/badges/StatusBadge';

.fund-transfers-table-container {
  .status-badge-container {
    &.reconciled {
      @include status-colors($success);
    }

    &.unreconciled {
      @include status-colors($orange);
    }
  }
}


.fund-transfer-status-badge-container {
  &.requested {
    @include status-colors($yellow);
  }

  &.scheduled {
    @include status-colors($yellow);
  }

  &.pending {
    @include status-colors($yellow);
  }

  &.completed {
    @include status-colors($success);
  }

  &.failed {
    @include status-colors($error);
  }
}
