@import '../styles/colors';
@import './button-sizes';
@import '../styles/effects';

.cta-button-container {
  &.shadow-button {
    @include box-shadow;
  }
  .cta-button-icon {
    $height: 20px;
    width: 20px;
    height: $height;
    display: flex;
    align-items: center;

    &.trailing-icon {
      margin-left: 9px;
    }

    &.leading-icon {
      margin-right: 9px;

      img {
        max-height: $height;
      }
    }
  }

  .base-button {
    display: flex;
    height: $base-button-height;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    color: white;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    padding: 0 40px;
    position: relative;

    .button-text {
      line-height: 1;
    }

    .arrow {
      font-family: system-ui;
    }

    .spinner-container {
      background-color: inherit;
      border-radius: inherit;

      svg {
        height: 20px;
      }
    }

    &.blue {
      background-color: $cta;

      &:hover {
        opacity: 80%;
      }
    }

    &.secondary {
      background-color: unset;
      border: 1px solid $border-secondary;
      color: $text-secondary;

      &:hover {
        background-color: $bg-primary;
      }
    }

    &.oauth-login {
      background-color: $bg-primary;
      color: $cta;
      border-width: 1px;
      border-color: transparent;
      border-radius: 8px;
      border-style: solid;
      transition: border-color 0.3s ease-in-out;

      &:hover {
        border-color: $cta;
      }
    }

    &.inverted {
      background-color: white;
      color: $cta;

      &:hover {
        opacity: 95%;
      }
    }

    &.inverted-light-blue {
      background-color: $bg-primary;
      color: $cta;

      &:hover {
        opacity: 95%;
      }
    }

    &.dark {
      background-color: $almost-black;
      font-size: 14px;

      &:hover {
        background-color: black;
      }
    }

    &.empty {
      background-color: unset;
      border: 1px solid $almost-black;
      color: $almost-black;

      &:hover {
        background-color: $almost-black;
        color: $white;

        .base-icon-container.dark {
          path {
            stroke: white;
          }
        }
      }
    }

    &.empty-no-frame {
      background-color: unset;
      color: $text-primary;

      &:hover {
        opacity: 80%;
      }

      &.disabled {
        background-color: unset;
      }
    }

    &.empty-red-frame {
      background-color: white;
      color: $error;
      border: 1px solid $error;

      &:hover {
        color: $error;
        background-color: $bg-primary;
      }
    }

    &.inverted-empty {
      background-color: unset;
      border: 1px solid white;
      color: $white;

      &:hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }

    &.white {
      background-color: white;
      color: $cta;
      border: 1px solid $light-blue;
      border-radius: 8px;

      &:hover {
        background-color: $cta;
        border-color: $cta;
        color: $white;
      }
    }

    &.bg-primary {
      background-color: $bg-primary;
      color: $light-grey;
      cursor: default;
      border: none;
    }

    &.light-blue {
      background-color: unset;
      border: 1px solid $border-secondary;
      color: $cta;

      &:hover {
        background-color: $bg-primary;
      }

      &.selected {
        background-color: $bg-primary;
        border-color: $light-blue;
      }
    }

    &.small {
      font-weight: normal;
      height: $small-button-height;
      font-size: 14px;
      border-radius: 8px;
      padding: 0 20px;

      &.empty-no-frame {
        padding: 0;
      }
    }

    &.disabled {
      background-color: $bg-disabled;
      color: $text-disabled;
      cursor: default;
      border: none;
      pointer-events: none;

      .base-icon-container {
        filter: grayscale(1);
      }
    }

    &.link {
      text-decoration: none;
    }

    &.loading {
      cursor: default;
      pointer-events: none;
    }
  }
}
