@import './breakpoints';
@import './colors';

@mixin box-shadow() {
  box-shadow: 0 17px 37px rgba(112, 144, 176, 0.12);
}

@mixin blue-card-background() {
  display: flex;
  align-items: center;
  background-color: $bg-primary;
  border: 1px solid $border-secondary;
  padding: 30px 20px;
  border-radius: 15px;
}

@mixin chip-background() {
  background-color: $light-grey;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 0 6px;
  line-height: 1;
}
