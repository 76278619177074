@import '../styles/colors';

@mixin icon-color($color) {
  &.stroke {
    path {
      stroke: $color;
    }
  }
  &.fill {
    path {
      fill: $color;
    }
  }
}

.base-icon-container {
  display: flex;
  margin: auto;

  &.dark {
    @include icon-color($text-primary);
  }
  &.light {
    @include icon-color(white);
  }
  &.grey {
    @include icon-color($light-grey);
  }
  &.blue {
    @include icon-color($cta);
  }
  &.secondary {
    @include icon-color($text-secondary);
  }
}
