@import '../styles/colors';
@import '../styles/positions';
@import '../styles/breakpoints';

.base-dialog-inner-container {
  visibility: hidden;
  opacity: 0;

  .base-dialog-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: $dialog-base-z-index;
    backdrop-filter: none;
  }

  .base-dialog-content {
    margin: auto;
    padding: 30px;
    border-radius: 22px;
    position: fixed;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 18px 62px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: $dialog-base-z-index + 1;

    .inner-header {
      display: flex;
      justify-content: flex-end;

      &.with-content {
        font-weight: 500;
        padding-bottom: 20px;
        border-bottom: 1px solid $border-secondary;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
      }

      .left-header-text {
        color: $light-grey;
      }

      .close-button {
        background-color: #eeeeee;
        color: $text-secondary;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        cursor: pointer;
        z-index: 1;
      }
    }

    .inner-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &.open {
    visibility: visible;
    opacity: 1;

    .base-dialog-overlay {
      backdrop-filter: none;
      &.solid {
        background: $bg-primary;
        backdrop-filter: none;
      }

      &.blur {
        background: none;
        backdrop-filter: blur(80px);
      }
    }
  }

  .external-header {
    position: absolute;
    padding: 100px;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: $dialog-base-z-index;

    .base-dialog-logo {
      width: 76px;
    }

    .close-text {
      color: $text-disabled;
      text-decoration: underline;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    gap: 20px;
  }

  @include max-width-small-desktop {
    .external-header {
      padding: 50px 100px;
    }
  }

  @include max-width-tablet {
    &.open {
      .base-dialog-overlay {
        &.blur {
          backdrop-filter: blur(40px);
        }
      }
    }
  }

  @include max-width-mobile {
    .base-dialog-content {
      width: 100%;
      position: fixed;
      top: unset;
      left: 0;
      bottom: 0;
      transform: translateY(100%);
      border-radius: 20px 20px 0 0;
      padding: 20px;
      display: block;

      .inner-header {
        .close-button {
          height: 30px;
          width: 30px;
        }

        .wizard-step-indicators-container {
          width: 0;
          visibility: hidden;
        }
      }

      .inner-content {
        width: 100%;
      }
    }

    &.open {
      .base-dialog-overlay {
        &.blur {
          backdrop-filter: blur(20px);
          transition: backdrop-filter 70ms;
        }
      }
      .base-dialog-content {
        transition: transform 500ms;
        transform: translateY(0);
      }
    }

    .external-header {
      justify-content: center;
      z-index: 12;
      height: 100%;
      pointer-events: none;

      .base-dialog-logo {
        height: 28px;
        width: 56px;
      }

      .close-text {
        position: absolute;
        bottom: 50px;
        pointer-events: auto;
      }
    }
  }
}
