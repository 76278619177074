.base-table-header-container {
  .header-cell {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    align-items: center;

    &.center {
      justify-content: center;
      text-align: center;
    }
    &.right {
      justify-content: flex-end;
      text-align: right;
    }

    .info-container {
      padding-top: 4px;
    }

    svg {
      margin-left: 5px;
      margin-top: 1px;
    }
  }
}
