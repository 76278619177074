@import '@grain/web-components/src/styles/colors.scss';
@import '@grain/web-components/src/badges/StatusBadge.scss';

.wallets-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  .wallets-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .wallets-controls-title {
      color: $light-grey;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
    }
  }
}

.settlement-ready-badge {
  padding-bottom: 3px;
  padding-left: 6.96px;
  padding-right: 6.96px;
  padding-top: 3px;

  @include status-colors($text-disabled);

  &.success {
    @include status-colors($success);
  }
}
