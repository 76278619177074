@import '../styles/breakpoints';
@import '../styles/colors';
@import './BaseMenuItem';

.expandable-menu-item-container {
  color: $text-secondary;
  margin-bottom: 16px;
  margin-top: 10px;
  border-bottom: 1px solid $border-secondary;
  border-top: 1px solid $border-secondary;

  .toggleable {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;
    margin-top: 16px;
    cursor: pointer;
    padding: $menu-item-padding;

    .title-and-item {
      display: flex;
      align-items: center;
      flex: 1;
      font-size: 14px;
      height: 36px;
      color: $text-secondary;
    }

    .expand-arrow {
      font-weight: 500;
    }

    &.hidden {
      display: none;
    }
  }

  .child-items {
    padding-left: 8px;
    flex-direction: column;
    display: none;
    margin-bottom: 10px;
  }

  &.expanded,
  &:hover {
    color: $light-grey;
  }

  &.expanded {
    .expand-arrow {
      transform: rotate(90deg);
    }

    .child-items {
      display: flex;
    }
  }

  @include max-width-tablet {
    .toggleable {
      display: none;
    }
    .child-items {
      padding-left: 0;
    }
  }

  @include max-width-mobile {
    .toggleable {
      display: flex;
    }
    .child-items {
      padding-left: 10px;
    }
  }
}
