@import '@grain/web-components/src/styles/colors';

.currency-pair-details-dialog-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  padding-top: 24px;

    .providers-container {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .providers-title {
        color: $text-secondary;
        font-size: 14px;
        line-height: 14px;
      }

      .providers-row {
        display: flex;
        flex-direction: row;
        gap: 8px;
        min-height: 30px;
      }
    }

    .partners-container {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .partners-title {
        color: $text-secondary;
        font-size: 14px;
        line-height: 14px;
      }

      .partners-row {
        color: $text-primary;
        font-size: 14px;
        line-height: 1.4;
        min-height: 14px;
      }
    }
}
