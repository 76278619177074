@import '../styles/colors';
@import '../styles/breakpoints';

.details-card-values-container {
  .detail {
    padding: 16px 0;
    margin: 0 10px;
    line-height: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &:not(:last-child) {
      border-bottom: 1px solid $border-secondary;
    }

    .title {
      font-size: 14px;
      color: $text-secondary;
    }

    .value {
      font-size: 14px;
      color: $text-primary;
      line-height: 1.5;
    }

    .link {
      text-decoration: underline;
    }
  }

  @include only-tablet-width {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    margin-top: 4px;

    .detail:nth-last-child(-n + 2) {
      // The last two cells in a grid. for a 2 columns grid, those will be the two bottom cells.
      border-bottom: unset;
    }
  }
}
