.spots-page-container {
  .spot-section-card {
    flex-grow: inherit;
  }
  .spots-page-empty-state {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 600px;

    .base-dialog-container {
      align-self: flex-end;
    }
  }
}
