@import '../styles/colors.scss';

.table-head {
  width: calc(100% - 1rem);

  .table-head-header-group {
    display: table;
    table-layout: fixed;
    width: 100%;

    .table-header {
      color: $light-grey;
      font-size: 14px;
      font-weight: 500;
      padding: 8px 5px 10px;;
      text-align: left;
      border-bottom: 1px solid $border-secondary;
      border-collapse: separate;

      .table-header-sortable-container {
        align-items: center;
        cursor: pointer;
        display: flex;
        gap: 5px;
        user-select: none;
      }

      &:first-child {
        padding-left: 10px;
      }

      &:last-child {
        padding-right: 10px;
      }
    }
  }
}
