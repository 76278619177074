@import '@grain/web-components/src/styles/colors';

.grey-label-subtext {
  color: $light-grey;
}

.delete-currency-pair-title {
  font-size: 24px;
  font-weight: 700;
  color: #2b3674;
  margin-bottom: 1rem;
  text-align: center;
}

.delete-currency-pair-content {
  color: #626b93;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.two-buttons-section-wrapper {
  margin-top: 2.5rem;
}

.delete-dialog-button {
  width: 13rem;
  height: 5rem;
}
