@import '@grain/web-components/src/styles/colors';

.app-layout-container {
  .base-side-bar-container {
    background: linear-gradient(180deg, #526bb8 0%, #91a4dd 100%);

    .logo-container {
      filter: brightness(0) invert(1);
    }

    .expandable-menu-item-container {
      color: $white;
      border-bottom: none;
      margin-bottom: 0;
    }

    .menu-item-text {
      color: $white;
    }

    .menu-item-container {
      color: $white;
      padding: 5px 5px;

      &:hover,
      &.selected {
        background: rgba(255, 255, 255, 0.2);
      }

      &.selected {
        .item-icon {
          color: $white;
        }
      }
    }
  }
}
