@import '@grain/web-components/src/styles/colors';
@import '@grain/web-components/src/buttons/button-sizes';
@import '@grain/web-components/src/table/BaseTable';
@import '@grain/web-components/src/layout/layout';

.map-transactions-data-container {
  --container-bottom-margin: 56px;
  @include max-width-small-desktop {
    --container-bottom-margin: 20px;
  }
  $container-bottom-margin: var(--container-bottom-margin);

  width: calc(100vw - #{$side-bar-width} - (2 * #{$page-side-padding}));
  margin-bottom: $container-bottom-margin;

  @include max-width-tablet {
    .cta-button-container {
      button {
        width: 100%;
      }
    }
  }

  .section-header-title-container {
    height: $small-button-height;
    margin-bottom: 20px;
  }

  .section-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
  }

  &.loading {
    .cta-button-container {
      display: none;
    }
  }
}
