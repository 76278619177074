@import '../styles/colors';

.page-breadcrumbs-container {
  display: flex;
  font-size: 12px;
  color: $cta;
  align-items: center;

  .breadcrumb {
    display: flex;
    align-items: center;

    .arrow-icon {
      margin: 0 5px;
      color: $light-grey;
    }

    .text-button.disabled {
      color: $light-grey;
    }
  }
}
