@import '../styles/colors';

.error-card-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-grow: 1;
  margin-top: -28px;
  max-width: 457px;

  .warning-icon {
    display: flex;
    padding: 11px;
    border-radius: 50%;
    background: rgba(255, 132, 132, 0.2);
    margin-bottom: 40px;
  }

  .error-title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 16px;
    color: $text-primary;
  }

  .error-reason {
    font-size: 16px;
    color: $light-grey;
  }
}
