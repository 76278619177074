@import '../styles/colors';

.indicator {
  &.live {
    color: $success;
  }

  &.sandbox,
  &.demo {
    color: $cta;
  }

  &.sm {
    font-size: 16px;
  }

  &.lg {
    font-size: 20px;
  }
}

.label-indicator {
  font-size: 10px;
  font-weight: 500;
  color: $cta;
  line-height: 7px;
  padding: 3px;
  background-color: white;
  border: 1px solid $cta;
  border-radius: 3px;
  margin-left: 5px;
}
