@import url('https://fonts.googleapis.com/css2?family=DM+Sans');
@import '../styles/colors';
@import '../styles/breakpoints';
@import '../styles/effects';

.base-field-container {
  width: 100%;

  .label-and-field {
    display: flex;
    flex-direction: column;

    .label-container {
      display: flex;
      color: $text-primary;

      .field-label {
        font-family: 'DM Sans', sans-serif;
        margin-bottom: 16px;
        font-size: 14px;
      }

      .required-indicator {
        color: $cta;
      }
    }

    &.side {
      flex-direction: row;
      align-items: center;

      .label-container .field-label {
        margin: 0 10px 0 0;
      }

      .field-inner-container {
        flex: 1;
      }
    }
  }

  .field-error {
    color: $error;
    font-size: 12px;
    margin-bottom: 8px;
    height: 16px;
  }

  &.shadow-field {
    fieldset {
      @include box-shadow;
    }
  }

  @include max-width-mobile {
    .field-error {
      margin-bottom: 8px;
    }
  }
}
