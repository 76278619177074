.currencies-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  height: 100%;

  .currencies-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  > :not(.card) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
