@import '../styles/colors';
@import './form-sizes';

$border-radius: 15px;
$small-border-radius: 10px;
$big-border-radius: 8px;
$border: 1px solid $border-secondary;
$input-height: 50px;
$small-input-height: 38px;
$extra-small-input-height: 32px;
$big-input-height: 76px;

.base-input-container {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  // Override the default blue background of html inputs
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }

  input::-webkit-inner-spin-button {
    display: none;
  }

  .base-input-inner-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .field-element {
      width: 100%;
      font-size: 14px;

      .MuiFormLabel-root.MuiInputLabel-root {
        font-size: 14px;
        color: $text-secondary;
        top: -6px;
      }

      .MuiInputBase-root.MuiInput-root.MuiInput-underline {
        &:before {
          border: none;
        }
        &:after {
          border: none;
        }
      }

      .MuiOutlinedInput-root {
        .MuiOutlinedInput-input,
        & {
          height: $input-height;
          padding-top: 0;
          padding-bottom: 0;
          font-family: 'DM Sans', sans-serif;
          color: $text-primary;
          align-items: center;
          background: white;
          border-radius: $border-radius;
          font-size: 14px;
          cursor: pointer;
        }

        .MuiOutlinedInput-input.MuiInputBase-input {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          display: block;
          line-height: $input-height;
        }

        &:hover,
        &.Mui-focused,
        &.focus {
          fieldset {
            border: $border;
          }
        }

        fieldset {
          border: $border;
          border-radius: $border-radius;

          legend {
            width: 0;
          }
        }
      }

      &.disabled {
        background-color: $bg-secondary;
        color: $light-grey;
        border-radius: $border-radius;
        .MuiInputBase-input {
          cursor: not-allowed;
        }

        fieldset.MuiOutlinedInput-notchedOutline {
          border-color: $border-secondary;
        }
      }

      &.small {
        .MuiOutlinedInput-root {
          height: $small-input-height;
          border-radius: $small-border-radius;

          fieldset {
            border-radius: $small-border-radius;
          }
        }

        .MuiInputBase-input.MuiOutlinedInput-input {
          height: $small-input-height;
          line-height: $small-input-height;
          border-radius: $small-border-radius;
        }
      }

      &.extra-small {
        .MuiOutlinedInput-root {
          height: $extra-small-input-height;
          border-radius: $small-border-radius;

          fieldset {
            border-radius: $small-border-radius;
          }
        }

        .MuiInputBase-input.MuiOutlinedInput-input {
          height: $extra-small-input-height;
          line-height: $extra-small-input-height;
          border-radius: $small-border-radius;
        }
      }

      &.big {

        > .MuiOutlinedInput-root {
          height: $big-input-height;
          border-radius: $big-border-radius;

          fieldset {
            border: 1px solid $border-primary;
            border-radius: $big-border-radius;
          }
        }

        > .MuiInputBase-input.MuiOutlinedInput-input {
          height: $big-input-height;
          line-height: $big-input-height;
          border-radius: $big-border-radius;
        }
      }

      &.borderless {
        fieldset {
          border: none;
        }
        .MuiOutlinedInput-root {
          &:hover,
          &.Mui-focused,
          &.focus {
            fieldset {
              border: none;
            }
          }
        }
      }

      &.error {
        .MuiOutlinedInput-root {
          &:hover,
          &.Mui-focused,
          &.focus {
            fieldset {
              border-color: $error;
            }
          }
        }

        fieldset {
          border-color: $error;
        }
      }
    }
  }

  &.with-icon {
    $padding: 40px;
    $distance: 12px;

    @mixin icon-position() {
      position: absolute;
      z-index: 1;
      height: 18px;
    }

    .icon-wrapper {
      display: flex;
      align-items: center;

      &.clickable {
        cursor: pointer;
      }
    }

    &.leading {
      .MuiInputBase-input.MuiOutlinedInput-input {
        padding-left: $padding;
      }
      svg:first-child {
        left: $distance;
        @include icon-position;
      }
    }
    &.trailing {
      .MuiInputBase-input.MuiOutlinedInput-input {
        padding-right: $padding;
      }
      svg:last-child {
        right: $distance;
        @include icon-position;
      }
    }
  }
}
