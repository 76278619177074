@import '../styles/colors';

$upload-section-border: $border-secondary;

.upload-section {
    background: $bg-primary;
    border-radius: 20px;
    border: 2px dashed $upload-section-border;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: border 0.24s ease-in-out;
    padding: 30px;
    height: 100%;

    .title {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      color: $text-primary;
    }

    .subtitle {
      color: $light-grey;
      font-size: 12px;
      margin-top: 8px;
    }

    .cta-button-container {
      margin-top: 30px;
    }

    &.focused {
      border-color: darken($upload-section-border, 10%);
    }

    &.drag-accept {
      border-color: $success;
    }
  }
