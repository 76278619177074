.spinner-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.dots {
    flex: 1;
  }

  &.bar > div {
    height: 4px;
    width: 215px;
  }
}
