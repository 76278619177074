@import '../styles/colors';
@import '../styles/effects';
@import '../styles/breakpoints';

.details-card-container {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 20px;
  @include box-shadow;

  $main-margin: 10px;

  .header {
    margin: $main-margin $main-margin 0 $main-margin;
    color: $text-primary;
  }

  .inner-details-card-container {
    padding: 0 10px 10px;

    .show-more-less-button {
      .cta-button-container {
        margin: 0 $main-margin;

        .base-button {
          width: 100%;
          height: 32px;
          margin-bottom: 10px;
          cursor: pointer;
        }
      }
    }
  }

  .button-footer {
    border-top: 1px solid $border-secondary;
    height: 54px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    &.multiple {
      .divider {
        width: 1px;
        height: 20px;
        background-color: $border-secondary;
      }
    }
  }
}
