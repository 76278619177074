.details-card-container {
  .editable-field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .edit-button {
      width: 20px;
      height: 20px;
    }
  }

  .main-details {
    display: flex;

    .column {
      &.main {
        flex: 1;

        .name {
          font-weight: 500;
          margin-bottom: 5px;
        }

        .grain-id {
          font-size: 12px;
        }
      }
    }
  }
}
