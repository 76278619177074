@import '../styles/colors';
@import '../styles/breakpoints';
@import '../styles/effects';

.filter-form-container {
  @include blue-card-background;
  align-items: flex-end;
  gap: 20px;
  margin-bottom: 30px;

  .children-container {
    flex: 1;
    display: flex;
    align-items: flex-end;
    gap: 20px;
  }

  .buttons-container {
    display: flex;
    align-items: center;
    gap: 10px;

    .text-button {
      font-weight: normal;
      font-size: 14px;
      white-space: nowrap;
    }
  }
}

@include max-width-tablet {
  .filter-form-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    padding: 20px 30px;

    .children-container {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      flex-wrap: wrap;
      width: 100%;
    }
  }
}

@include max-width-mobile {
  .filter-form-container {
    .children-container {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .buttons-container {
      .cta-button-container {
        flex: 1;

        .base-button {
          width: 100%;
        }
      }
    }
  }
}
