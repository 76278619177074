@import '../styles/colors';
@import '../styles/breakpoints';

$table-border-radius: 15px;
$cell-horizontal-padding: 10px;
$large-rows-addition: 20px;
$outer-cells-padding: 30px;

.base-table-container {
  display: flex;
  flex-direction: column;

  .base-table-wrapper {
    overflow: auto;
    position: relative;

    &:not(.borderless) {
      border: 1px solid $border-secondary;
      border-radius: $table-border-radius;
    }

    .base-table {
      border-spacing: 0;
      width: 100%;

      &.fixed-layout {
        table-layout: fixed;
      }

      thead {
        background-color: $bg-secondary;

        tr {
          height: 70px;

          th {
            font-size: 16px;
            font-weight: normal;
            color: $text-primary;
            padding: 0 $cell-horizontal-padding;
            position: sticky;
            top: 0;
            background-color: $bg-secondary;

            &.sortable {
              cursor: pointer;
            }

            &:first-child {
              padding-left: $outer-cells-padding;

              :not(.borderless) {
                border-top-left-radius: $table-border-radius;
              }
            }

            &:last-child {
              padding-right: $outer-cells-padding;

              :not(.borderless) {
                border-top-right-radius: $table-border-radius;
              }
            }
          }
        }
      }

      --row-height: 60px;
      tbody {
        tr {
          height: var(--row-height);

          &.clickable {
            cursor: pointer;

            &:hover {
              background: rgba($bg-primary, 0.04);
            }
          }

          td {
            font-size: 16px;
            color: $light-grey;
            padding: 0 $cell-horizontal-padding;
            text-align: left;

            &.center {
              text-align: center;
            }

            &.right {
              text-align: right;
            }

            &:first-child {
              padding-left: $outer-cells-padding;
            }

            &:last-child {
              padding-right: $outer-cells-padding;
            }
          }

          &:nth-child(even) {
            background: $bg-secondary;
          }
        }

        .expand-arrow {
          color: $text-primary;
          cursor: pointer;
          display: flex;
          margin: auto;
          justify-content: flex-end;

          .down {
            transform: rotate(90deg);
          }
        }
      }
      &.large-rows {
        tbody {
          tr {
            height: var(--row-height) + $large-rows-addition;
          }
        }
      }
    }

    .base-table-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: $bg-secondary;
      z-index: 1;
      visibility: visible;
      opacity: 0.9;
    }
  }

  .base-table-footer-container {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  &.no-header {
    background-color: white;
    padding: 0 20px;

    &:not(.borderless) {
      border: 1px solid $border-secondary;
      border-radius: 10px;
    }

    .base-table-wrapper {
      &:not(.borderless) {
        border: none;
        border-radius: 10px;
      }

      .base-table {
        thead {
          display: none;
        }
      }
    }
  }

  &.lean {
    .base-table-wrapper {
      border: none;
      border-radius: 0;

      .base-table {
        thead {
          background-color: white;

          tr {
            height: 30px;
            vertical-align: top;

            th {
              font-size: 14px;
              font-weight: 500;
              color: $light-grey;
              border-bottom: 1px solid $border-secondary;
              background-color: white;

              &:first-child {
                padding-left: $cell-horizontal-padding;
              }

              &:last-child {
                padding-right: $cell-horizontal-padding;
              }
            }
          }
        }

        tbody {
          tr {
            height: 38px;

            td {
              font-size: 14px;
              color: $text-primary;
              padding: 14px $cell-horizontal-padding 6px;

              &:first-child {
                padding-left: $cell-horizontal-padding;
              }

              &:last-child {
                padding-right: $cell-horizontal-padding;
              }
            }

            &:nth-child(even) {
              background: white;
            }
          }
        }
      }
    }
  }

  @include max-width-small-desktop {
    .base-table-wrapper {
      .base-table {
        thead {
          tr {
            th {
              font-size: 14px;
            }
          }
        }

        tbody {
          tr {
            td {
              font-size: 14px;
            }
          }
        }
      }
    }

    &.lean {
      .base-table-wrapper .base-table {
        thead {
          tr {
            height: 20px;
            th {
              font-size: 12px;
              padding: 0 5px;
            }
          }
        }
        tbody {
          --row-height: 28px;
          $first-row-gap: 10px;
          tr {
            height: unset;
            &:first-child {
              td {
                padding-top: $first-row-gap;
                height: unset;
              }
            }
            td {
              font-size: 12px;
              line-height: 2;
              padding: 0 5px;
            }
          }
        }
      }
    }
  }

  @include max-width-tablet {
    .base-table-wrapper {
      .base-table {
        &.fixed-layout {
          table-layout: auto;
        }

        thead {
          tr {
            th {
              font-size: 12px;
            }
          }
        }
        tbody {
          tr {
            td {
              font-size: 12px;
            }
          }
        }
      }
    }

    &.lean {
      .base-table-wrapper .base-table {
        thead {
          tr {
            height: 25px;
          }
        }
      }
    }
  }

  @include max-width-mobile {
    .base-table-wrapper {
      .base-table {
        thead {
          tr {
            height: 60px;
          }
        }
        --row-height: 55px;
        tbody {
          tr {
            height: var(--row-height);
          }
        }
        &.large-rows {
          tbody {
            tr {
              height: var(--row-height) + $large-rows-addition;
            }
          }
        }
      }
    }

    &.lean {
      .base-table-wrapper .base-table {
        thead {
          tr {
            th {
              font-size: 10px;
            }
          }
        }
        tbody {
          tr {
            td {
              font-size: 10px;
              padding: 0 5px;
            }
          }
        }
      }
    }
  }
}

:export {
  rowHeight: var(--row-height);
  largeRowsAddition: $large-rows-addition;
}
