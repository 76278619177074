@import '../styles/colors';

.base-checkbox-container {
  display: flex;
  align-items: center;
  gap: 5px;

  .checkbox-label {
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    color: $text-primary;
  }
}
