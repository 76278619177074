@import '../styles/colors';

.base-table-empty-state-container {
  height: 300px;
  border: 1px solid $border-secondary;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $bg-secondary;
  border-radius: 25px;

  .warning-icon-empty-state {
    width: 50px;
    height: 50px;
    background: rgba($light-grey, 0.2);
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .text {
    margin-top: 20px;
    font-size: 14px;
    color: $light-grey;
  }
}
