@import '../styles/colors';
@import '../styles/breakpoints';

.dialog-success-step-container {
  height: 375px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .success-image {
    height: 150px;
  }

  .details {
    padding: 10px;
    background-color: $bg-secondary;
    border: 1px solid $border-secondary;
    border-radius: 10px;
    margin-top: auto;

    .detail {
      &:not(:last-child) {
        border-right: 1px solid $border-secondary;
      }
    }
  }

  .note {
    margin-top: 15px;
    margin-bottom: 35px;
    display: flex;
    align-items: center;
    color: $light-grey;
    font-size: 13px;
  }

  @include max-width-mobile {
    .details {
      .detail {
        border-bottom: 1px solid $border-secondary;

        &:not(:last-child) {
          border-right: none;
        }
      }
    }

    .note {
      text-align: center;
    }
  }
}
