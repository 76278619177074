@import '@grain/web-components/src/styles/colors';

.penny-test-base-dialog-content {
  width: 600px;

  .multi-steps-dialog-container {
    width: 100%;
    min-height: 356px;
    flex-direction: row;
    align-items: normal;
    justify-content: center;

    .dialog-success-step-container {
      height: 356px;
    }
  }

  &.error {
    .multi-steps-dialog-container {
      min-height: 240px;

      .error-card-container {
        margin-top: unset;
      }
    }
  }

  .penny-test-base-dialog {
    display: flex;
    flex-direction: column;

    .base-input-container {
      .flag-dropdown {
        min-width: unset;
      }
    }

    .cta-button-container {
      border: none;
      margin: 30px 0 0;
      padding: 0;

      .base-icon-container {
        margin: 0 auto;
      }
    }
  }
}
