@import '../styles/colors.scss';

.table-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .table-wrapper {
    height: 100%;

    .table-body-loader {
      position: absolute;
      color: $cta;
      background-color: white;
      // a hack to make the loader appear instead of the table header border
      top: 32px;
      width: 100%;
      height: 2px;
    }

    .table {
      border-collapse: collapse;
      border-spacing: 0 8px;
      margin-top: -8px;
      width: 100%;
      height:100%;
    }
  }

  .table-pagination {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: auto;

    .table-divider {
      border-color: $border-secondary;
      border-style: solid;
      border-width: 0 0 1px 0;
      margin: 0;
    }
  }
}
