@import '../styles/colors';

.card {
    background-color: white;
    border-radius: 15.91px;
    box-shadow: 0 20px 40px 0 #7090B01F;
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;

    &.card-clickable {
        cursor: pointer;
        border: 2px solid transparent;
        transition: border 0.3s ease-out;
        &:hover {
          border: 2px solid $light-blue;
          transition: border 0.3s ease-out;
        }
    }
}
