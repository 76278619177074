@import '@grain/web-components/src/styles/colors';
@import '@grain/web-components/src/badges/StatusBadge';

.customer-status-audits-table {
  .status-badge-container {
    &.rejected {
      @include status-colors($error);
    }

    &.no-issues-found {
      @include status-colors($cta);
    }

    &.found-issues {
      @include status-colors($orange);
    }
  }
}
