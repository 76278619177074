@import '../styles/breakpoints';
@import '../styles/colors';

.message-dialog-content-container.base-dialog-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  max-width: 640px;

  .title {
    font-size: 24px;
    color: $text-primary;
    font-weight: bold;
    margin-bottom: 18px;
  }

  .content {
    font-size: 16px;
    color: $text-secondary;

    &.cta-question {
      margin-top: 20px;
      max-width: 430px;
    }
  }

  @include max-width-mobile {
    max-width: unset;
  }
}
