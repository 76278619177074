@import '@grain/web-components/src/badges/StatusBadge';
@import '@grain/web-components/src/styles/colors';

.scheduler-jobs-table-container {
  .status-badge-container {
    &.enabled {
      @include status-colors($success);
    }

    &.disabled {
      @include status-colors($text-disabled);
    }
  }
}
