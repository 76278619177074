@import '../styles/colors';
@import 'StatusBadge';

.hedge-status-badge-container {
  &.active {
    @include status-colors($cta);
  }

  &.funded {
    @include status-colors($orange);
  }

  &.completed {
    @include status-colors($success);
  }

  &.cancelled {
    @include status-colors($text-disabled, #{$bg-disabled});
  }

  &.overdue {
    @include status-colors($error);
  }

  &.inprocess {
    @include status-colors($orange);
  }
}
