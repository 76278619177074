@import '../styles/colors';

.text-button-container {
  .arrow {
    font-family: system-ui;
  }

  a,
  a:-webkit-any-link {
    text-decoration: none;
    color: inherit;
  }

  .text-button {
    // State
    display: inline-flex;
    align-items: center;

    &.reverse {
      flex-direction: row-reverse;
      justify-content: flex-end;

      .arrow {
        transform: rotate(180deg);
      }
    }

    &:not(.light) {
      font-weight: bold;
    }
    cursor: pointer;

    &.underline-text {
      border-bottom-width: 1px;
      border-bottom-style: solid;
    }

    // Colors

    &.blue {
      color: $cta;
      border-color: $cta;
    }

    &.light-grey {
      color: $light-grey;
      border-color: $light-grey;
    }

    &.white {
      color: $white;
      border-color: $white;
    }

    &.text-primary {
      color: $text-primary;
      border-color: $text-primary;
    }

    &.disabled {
      pointer-events: none;
      cursor: default;
      color: $text-disabled;
    }

    // Size

    &.regular {
      font-size: 16px;
    }

    &.small {
      font-size: 14px;
    }
  }
}
