@import '../styles/colors';
@import '../styles/breakpoints';

$selector-main-height: 38px;
:root {
  --selector-height: #{$selector-main-height};
  @include max-width-small-desktop {
    --selector-height: 33px;
  }
  @include max-width-tablet {
    --selector-height: #{$selector-main-height};
  }
}
$selector-height: var(--selector-height);

$option-main-font-size: 14px;
$option-main-height: 28px;

.horizontal-selector-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-self: flex-start; // Fits to content size
  overflow-x: auto;
  overflow-y: hidden;
  min-height: $selector-height;
  padding: 5px;
  background: $bg-primary;
  border-radius: 8px;
  flex-shrink: 0;
  //Handle multiple rows for large amount of options
  max-width: 100%;
  flex-wrap: wrap;
  row-gap: 5px;

  .option {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $option-main-font-size;
    height: $option-main-height;
    padding: 8px;
    text-align: center;
    color: $light-grey;
    flex: 1;
    cursor: pointer;
    white-space: nowrap;

    &.selected {
      background-color: white;
      font-weight: 500;
      border-radius: 5px;
      color: $text-primary;
    }
  }
}
