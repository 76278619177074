@import '../styles/colors';

.sign-out-container {
  display: flex;
  align-items: center;
  color: $text-secondary;
  cursor: pointer;
  padding-top: 12px;
  font-size: 14px;
  gap: 12px;

  svg {
    color: $text-secondary;
  }

  .sign-out-button {
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
  }
}
