@import '../styles/breakpoints';
@import '../styles/colors';

.wallet-card {
  gap: 16px;
  width: 320px;

  @include max-width-mobile {
    width: 100%;
  }

  .wallet-card-header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .wallet-card-title {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      font-weight: 600;
      font-size: 18px;
      gap: 10px;
      line-height: 18px;
      color: $text-primary;
    }
  }

  .wallet-card-details {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .wallet-card-detail {
    align-items: center;
    color: $text-secondary;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    justify-content: space-between;
  }
}

.payment-types {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
