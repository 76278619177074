@import '../styles/colors';

.name-tag-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 0;

  .initials {
    background-color: $purple;
    height: 40px;
    width: 40px;
    border-radius: 8px;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: white;
  }

  .name-and-email {
    overflow: hidden;
    gap: 1px;
    display: flex;
    flex-direction: column;

    .user-name-and-suffix {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
    }

    .user-name,
    .user-email {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .user-name {
      font-size: 14px;
      color: $text-primary;
      font-weight: 500;
    }
    .user-email {
      font-size: 12px;
      color: $light-grey;
    }
  }
}
