@import '../styles/colors';

.base-tabs-container {
  display: flex;
  width: 100%;
  border-bottom: 1px solid $border-secondary;
  margin-bottom: 20px;
  margin-top: 14px;

  .tab {
    font-weight: 500;
    color: $text-primary;
    cursor: pointer;
    padding-bottom: 1rem;
    position: relative;

    &:not(:last-child) {
      margin-right: 24px;
    }

    &:hover {
      color: $cta;
    }

    &.selected {
      color: $cta;

      &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        border-bottom: 1px solid $cta;
      }
    }

    &.disabled {
      color: $light-grey;
      pointer-events: none;
    }
  }
}
