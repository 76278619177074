@import '@grain/web-components/src/styles/colors';

.details-page-container {
  .page-content {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
    gap: 30px;

    .left-pane {
      display: flex;
      flex-direction: column;
      width: 400px;
      gap: 30px;

      .details-card-container {
        .details-header {
          font-size: 20px;
          padding: 15px 5px;
          border-bottom: 1px solid $border-secondary;
          display: flex;
          justify-content: space-between;

          .edit-button {
            margin-right: 5px;
          }
        }

        .main-details {
          font-size: 20px;
          padding: 20px;
          border-bottom: 1px solid $border-secondary;
        }
      }

      .cta-button-container {
        button {
          width: 100%;
        }
      }
    }

    .right-pane {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }
}
