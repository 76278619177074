@import '@grain/web-components/src/styles/colors';
.currencies-table-action-item {
  z-index: 10;
  position: relative;
  visibility: hidden;
}

.table-row:hover .currencies-table-action-item {
  visibility: visible;
}

.vertical-line {
  width: 0.05rem;
  height: 1.4rem;
  background-color: #DFE6F6;
}

.flex-buttons {
  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    margin: 0 0.3rem;
  }
}
