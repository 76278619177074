@import '../styles/colors.scss';

.collapsible-root {
  .toggle-area {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding-bottom: 16px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 16px;
    user-select: none;

    .toggle-area-center {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 8px;

      .toggle-area-text {
        color: $light-grey;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        text-wrap: nowrap;
      }
    }
  }
}
