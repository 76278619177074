@import '../styles/colors';
@import '../styles/breakpoints';

.dialog-details-step-container {
  width: 100%;

  .main-title {
    font-size: 24px;
    color: $text-primary;
    line-height: 1.2;
    text-align: center;
  }

  .details {
    display: flex;
    width: 100%;
    height: 80px;

    .detail {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      color: $light-grey;
      gap: 12px;
      line-height: 1;
      height: 100%;

      .title {
        font-size: 14px;
      }

      .value {
        font-size: 16px;
        color: $text-primary;
        text-align: center;
      }
    }
  }

  @include max-width-mobile {
    .main-title {
      font-size: 24px;
    }

    .details.details-wrapper {
      flex-direction: column;
      padding: 0;
      margin-bottom: 24px;
      height: unset;
      background-color: unset;
      border: none;

      .detail {
        align-items: flex-start;
        background-color: unset;
        border: none;
        border-bottom: 1px solid $border-secondary;
        border-radius: 0;
        height: 70px;
        padding: 0;
        margin: unset;
        flex: unset;
      }
    }
  }
}
