@import '../styles/colors';
@import '../styles/breakpoints';
@import '../styles/effects';

:root {
  --section-card-padding: 20px;
  @include max-width-mobile {
    --section-card-padding: 15px;
  }
}
$section-card-padding: var(--section-card-padding);

.section-card-container {
  padding: 0 0 $section-card-padding 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;

  &.with-background {
    background-color: white;
    @include box-shadow;
    border-radius: 25px;
    padding: $section-card-padding;
  }

  .section-header {
    .section-header-title-container {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      justify-content: space-between;

      .section-header-left {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: $text-primary;

        .section-header-icon {
          height: 24px;
          width: 24px;
          color: $white;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          margin-right: 14px;

          svg {
            margin: auto;
          }

          .header-icon-image {
            &.light {
              color: $white;
            }
          }
        }
      }

      .section-header-badge {
        margin-left: 10px;
      }
    }

    .section-subtitle {
      font-size: 13px;
      color: $light-grey;
      flex-shrink: 0;
      margin-bottom: 30px;
    }
  }

  .section-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .MuiSkeleton-root {
    border-radius: 10px;
    height: 100%;
    background-color: $border-primary;

    &:not(.rerender) {
      flex: 1;
    }
  }

  @include max-width-small-desktop {
    .section-header {
      .section-header-title-container {
        .section-header-left {
          font-size: 14px;
        }
      }
      .section-subtitle {
        margin-bottom: 15px;
        font-size: 12px;
      }
    }
  }

  @include max-width-tablet {
    .section-header {
      .section-subtitle {
        font-size: 12px;
      }
    }
  }
}
