@import '../styles/colors';

.flag-selector {
  display: flex;
  align-items: center;
  .currency {
    margin-left: 3px;
    font-size: 14px;
    line-height: 18px;
    color: $text-primary;
  }

  .flag-dropdown {
    min-width: 70px;
    .MuiInputBase-root.MuiAutocomplete-inputRoot {
      font-size: 14px;
      padding-right: 18px;

      &.Mui-disabled {
        padding-right: 0;
        .MuiAutocomplete-endAdornment {
          display: none;
        }
      }

      .MuiAutocomplete-input {
        padding: 0 4px 0 6px;
        cursor: pointer;
        -webkit-text-fill-color: $text-primary;
        &:disabled {
          cursor: default;
        }
      }
    }
  }

  .flag-container {
    .flag {
      border-radius: 6px;
    }
  }
}
