@import '../styles/colors';

.dialog-base-step-container {
  width: 100%;

  .error {
    font-size: 14px;
    color: $error;
  }

  .cta-button-container {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid $border-secondary;
    width: 100%;

    button {
      width: 100%;
    }
  }
}
