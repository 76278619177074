.align-all-fields {
  .label-and-field.side {
    .label-container {
      flex: 1 1 0;
    }

    .field-inner-container {
      flex: 3 3 0;
    }
  }
}
