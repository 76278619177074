@import '../styles/colors';

.flag-container {
  display: flex;
  .flag {
    border: 1px solid $border-secondary;
    border-radius: 8px;
    // Weird bug: https://stackoverflow.com/questions/77529712/lipis-flag-icons-weird-character-in-production
    &:before {
      content: none;
    }
  }
}
