@import '../styles/colors';
@import '../styles/breakpoints';

$horizontal-space-size: 20px;

@mixin blue-box($display: true) {
  @if $display == true {
    background: $bg-primary;
    border: 0.5px solid $border-secondary;
    border-radius: 15px;
  } @else {
    background: unset;
    border: none;
    border-radius: 0;
  }
}

@mixin two-column-grid() {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: $horizontal-space-size;

  .double {
    grid-column: 2 span;
  }
}

.base-fields-mapper-container {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  overflow: auto;
  @include blue-box();

  .mapping-section-container {
    width: 100%;
    display: flex;

    .mapping-section {
      flex: 1;
      color: $light-grey;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;

      .mapping-section-header {
        margin-bottom: 27px;
      }

      + .mapping-section {
        margin-inline-start: $horizontal-space-size;
        padding-inline-start: $horizontal-space-size;
        border-inline-start: 1px solid $border-secondary;

        @include max-width-tablet {
          margin-inline-start: 0;
          padding-inline-start: 0;
          border-inline-start: unset;
        }
      }

      &.double {
        flex: 2;

        .mapping-section-content {
          @include two-column-grid();
        }
      }

      @include max-width-small-desktop {
        .mapping-section-content {
          .label-container {
            label {
              font-size: 13px;
            }
          }
        }
      }
    }
  }

  @include max-width-tablet {
    $vertical-margin: 30px;
    .mapping-section-container {
      display: unset;

      .mapping-section-header {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: $vertical-margin;

        &:after {
          content: '';
          flex: 1;
          border-bottom: 1px solid $border-secondary;
        }
      }

      .mapping-section-content {
        @include blue-box();
        @include two-column-grid();
        padding: 20px 30px 0;
        margin-bottom: $vertical-margin;

        .label-container {
          label {
            font-size: 14px;
          }
        }
      }

      &:last-child {
        .mapping-section-content {
          margin-bottom: 0;
        }
      }
    }

    @include max-width-tablet {
      flex-direction: column;
      padding: 0;

      @include blue-box(false);
    }
  }
}
