@import '../styles/colors.scss';

.table-empty-state-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 8px;

  height: 100%;
  width: 100%;

  background-color: $bg-secondary;
  color: $light-grey;
}
