@import '@grain/web-components/src/styles/colors';
@import '@grain/web-components/src/badges/StatusBadge';

.customer-status-badge-container {
  text-transform: capitalize;

  &.approved {
    @include status-colors($success);
  }

  &.pending {
    @include status-colors($orange);
  }

  &.rejected {
    @include status-colors($error);
  }
}
