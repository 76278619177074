@import '@grain/web-components/src/styles/colors';
.manual-hedging-page {
  .map-hedges-data-container {
    margin-top: 16px;

    .menu-item-text {
      font-weight: 500;
    }

    .skip {
      .menu-item-text {
        color: $yellow;
      }
    }

    .complete {
      .menu-item-text {
        color: $success;
      }
    }

    .error {
      .menu-item-text {
        color: $error;
      }
    }
  }
}
