@import '../styles/colors';
@import '../styles/breakpoints';

.paginator-container {
  width: fit-content;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 20px;

  .paginator-controls {
    align-items: center;
    background-color: $bg-secondary;
    border: 1px solid $border-secondary;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    gap: 9px;
    height: 38px;
    padding-inline: 10px;

    .paginator-button {
      background-color: unset;
      border: none;
      color: $light-grey;
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;
      height: 38px;
      line-height: 16px;
      padding-inline: 0;
      text-align: center;

      &:disabled {
        cursor: default;
        opacity: 0.3;
      }
    }

    .paginator-page-input {
      border: 1px solid $border-secondary;
      border-radius: 5px;
      color: $text-primary;
      height: 24px;
      font-size: 16px;
      line-height: 16px;
      min-width: 28px;
      text-align: center;
    }
  }

  .paginator-total-items {
    font-size: 16px;
    line-height: 21px;
    color: $light-grey;
  }

  @include max-width-mobile {
    align-items: flex-start;
    flex-direction: column;
  }
}
