@import '../styles/breakpoints';
@import '../styles/colors';

.expandable-mapping-item-container {
  color: $cta;
  padding-top: 6px;

  .toggleable {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    cursor: pointer;

    .expand-arrow {
      font-weight: bold;
    }

    &.hidden {
      display: none;
    }
  }

  .child-items {
    flex-direction: column;
    display: none;
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid $border-secondary;
  }

  &.expanded,
  &:hover {
    color: $almost-black;
  }

  &.expanded {
    .expand-arrow {
      transform: rotate(90deg);
    }

    .child-items {
      display: flex;
    }
  }
}
