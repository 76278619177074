@import '../styles/colors';
@import '../styles/effects';

.user-menu-container {
  background-color: white;
  border-radius: 16px;
  padding: 10px;
  border: 1px solid $border-secondary;

  .user-details {
    display: flex;
    align-items: center;
    cursor: pointer;

    .expand-arrow {
      margin-left: 20px;
    }
  }

  .expanded-menu {
    display: none;
    border-top: 1px solid $border-secondary;
    margin-top: 12px;
  }

  &.expanded {
    @include box-shadow;

    .expand-arrow {
      transform: rotate(180deg);
    }
    .expanded-menu {
      display: block;
    }
  }
}
