@import '../styles//colors.scss';

.table-foot {
  .table-footer-group {
    height: 38px;

    .table-footer {
      color: $light-grey;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      padding-inline: 5px;
      text-align: left;

      &:first-child {
        padding-left: 10px;
      }

      &:last-child {
        padding-right: 10px;
      }
    }

    &:first-child {
      border-top: 1px solid $border-secondary;
      padding-inline: 10px;
    }
  }
}
