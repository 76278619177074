@import '../styles/colors';
@import '../styles/breakpoints';

$menu-item-padding: 0 10px;

.menu-item-container {
  display: flex;
  align-items: center;
  border-radius: 10px;
  height: 38px;
  width: 100%;
  text-decoration: none;
  color: $text-secondary;
  font-size: 14px;
  line-height: 1;
  padding: $menu-item-padding;
  cursor: pointer;
  margin-bottom: 5px;

  .item-icon {
    flex-shrink: 0;
  }

  .left.item-icon {
    margin-right: 14px;
  }

  .right.item-icon {
    margin-left: 10px;
  }

  .menu-item-text {
    flex-shrink: 0;
  }

  &:hover,
  &.selected {
    background: $bg-primary;
  }

  &.disable {
    pointer-events: none;
  }

  &.selected {
    .item-icon {
      color: $cta;
    }
    color: $text-primary;
    font-weight: 500;
  }

  @include max-width-tablet {
    height: 34px;

    .right.item-icon {
      display: none;
    }
  }
}
