@import '../styles/colors';

.currency-amount-input {
  .flag-selector {
    padding-left: 24px;
    border-left: 1px solid $border-secondary;
    margin-left: 24px;
  }

  .help-text {
    color: $light-grey;
    font-size: 14px;

    &.error {
      color: $error;
    }
  }
}
