@import '../styles/colors';
@import '../styles/breakpoints';

.base-table-paginator-container {
  display: flex;
  align-items: center;

  .pagination {
    background-color: $bg-secondary;
    border-radius: 10px;
    border: 1px solid $border-secondary;
    height: 42px;
    display: flex;
    align-items: center;
    padding: 0 15px;

    .pagination-button {
      background-color: unset;
      border: none;
      cursor: pointer;
      color: $light-grey;
      display: flex;

      &:disabled {
        cursor: default;
        opacity: 0.3;
      }
    }

    .page-input {
      border: 1px solid $border-secondary;
      border-radius: 5px;
      text-align: center;
      width: 50px;
      height: 30px;
      color: $text-primary;
    }
  }

  .results {
    margin-left: 32px;
    color: $light-grey;
  }

  @include max-width-mobile {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;

    .results {
      margin: 0;
    }
  }
}
