.base-dropdown-container {
  width: 100%;
}

.MuiList-root.MuiList-padding.MuiMenu-list {
  max-height: 300px;

  .MuiMenuItem-root {
    font-size: 14px;
  }
}
