@import '@grain/web-components/src/styles/colors';

.dialog-side-label {
  margin-right: 1rem;
  color: $text-primary;
  font-size: 14px;
}

.grey-label-subtext {
  color: $light-grey;
}

.input-flex {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.dialog-input-wrapper {
  width: 60%;
  display: flex;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.add-currency-dialog-error {
  color: $error;
  font-size: 12px;
  margin-left: 1.5rem;
  font-weight: 400;
}
