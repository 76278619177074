@import '../styles/breakpoints';

.multi-steps-dialog-container {
  width: 640px;
  min-height: 375px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include max-width-mobile {
    width: 100%;
    height: unset;
  }
}
