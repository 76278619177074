@import '../styles/breakpoints';
@import '../styles/colors';

.searchbar-input {
  border-radius: 8px;
}

.searchbar-container {
  width: 100%;

  @include min-width-small-desktop {
    width: 350px;
  }

  // a hack to remove text selection border radius
  .base-input-container .base-input-inner-container .field-element .MuiInputBase-input.MuiOutlinedInput-input {
    border-radius: 0;
    background-color: transparent;

    &::placeholder {
      color: $text-secondary;
      opacity: 0.5;
    }
  }
}

.searchbar-input-search-icon {
  margin-right: 8px;
}
