@import '../styles//breakpoints.scss';

.wallet-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 320px);
  grid-auto-rows: 1fr;
  gap: 24px;

  @include max-width-mobile {
    gap: 20px;
    grid-template-columns: 1fr;
  }
}
