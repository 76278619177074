@import '../styles/breakpoints';

:root {
  --side-bar-width: 240px;
  @include max-width-small-desktop {
    --side-bar-width: 210px;
  }
  @include max-width-tablet {
    --side-bar-width: 60px;
  }
  @include max-width-mobile {
    --side-bar-width: 0;
  }
}
$side-bar-width: var(--side-bar-width);

$top-bar-height: 86px;

:root {
  --page-top-padding: 40px;
  @include max-width-small-desktop {
    --page-top-padding: 30px;
    @include max-width-tablet {
      --page-top-padding: 20px;
      @include max-width-mobile {
        --page-top-padding: 30px;
      }
    }
  }
}
$page-top-padding: var(--page-top-padding);

:root {
  --page-side-padding: 40px;
  @include max-width-small-desktop {
    --page-side-padding: 30px;
    @include max-width-tablet {
      --page-side-padding: 20px;
      @include max-width-mobile {
        --page-side-padding: 15px;
      }
    }
  }
}
$page-side-padding: var(--page-side-padding);

