@import '../styles/colors';
@import '../styles/breakpoints';

.base-data-mapper-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  .map-data-table-container {
    $border-padding: 20px;
    margin-block-start: $border-padding;
    padding-block-start: $border-padding;
    border-block-start: 1px solid $border-secondary;

    @include max-width-tablet {
      display: none;
      margin-block-start: 0;
      padding-block-start: 0;
      border-block-start: unset;
    }

    .base-table-container {
      height: 100%;

      $border-width: 1px;
      td,
      th {
        border: $border-width solid transparent;
      }

      .highlighted {
        background: rgba($cta, 0.1);
        border-width: 0 $border-width;
        border-style: solid;
        border-color: $cta;
      }
    }
  }

  @include max-width-small-desktop {
    .field-error {
      margin-bottom: 5px;
    }
    .base-table-container .base-table-wrapper .base-table thead tr {
      height: 50px;
    }
  }

  @include max-width-tablet {
    .field-error {
      margin-bottom: 15px;
    }
  }
}
