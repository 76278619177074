@import '../styles/colors';
@import '../styles/breakpoints';
@import './layout';

.base-side-bar-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: $side-bar-width;
  padding: 30px 20px 20px;
  background-color: white;
  border-right: 1px solid $border-secondary;

  --main-padding: 40px;
  @include max-width-small-desktop {
    --main-padding: 30px;
  }
  $main-padding: var(--main-padding);
  $main-padding-adjusted: calc(var(--main-padding) - 15px);

  .logo-container {
    padding-bottom: $main-padding;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .selected-organization-badge-section {
    margin-bottom: 15px;
  }

  .side-bar-footer {
    padding-top: 15px;
    margin-top: auto;
  }

  .side-bar-items {
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    height: 100%;
    padding: $main-padding-adjusted 0 $main-padding;
  }

  @include max-width-small-desktop {
    padding: 20px 15px;
  }

  @include max-width-tablet {
    padding: 12px;

    .logo-container {
      flex-direction: column;
    }

    .side-bar-items {
      .menu-item-container {
        padding: 0;
        justify-content: center;
        flex-direction: column;
        position: relative;

        .menu-item-text {
          display: none;
        }

        svg {
          margin: 0;
        }
      }
    }
  }
}
