@import '../styles/colors';
@import '../styles/positions';
@import '../styles/breakpoints';
@import './layout';

.base-top-bar-container {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $border-secondary;
  height: $top-bar-height;
  padding: 0 $page-side-padding;
  width: calc(100% - #{$side-bar-width});
  z-index: $dialog-base-z-index - 1;
  backdrop-filter: blur(20px);
  background-color: rgba($white, 0.8);

  .titles {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      font-size: 20px;
      font-weight: bold;
      color: $text-primary;
      line-height: 1;
    }

    .subtitle {
      font-size: 14px;
      color: $text-secondary;
    }

    .breadcrumbs-button-container {
      display: flex;
      justify-content: space-between;
      padding-bottom: 30px;
    }
  }

  .user-menu-container {
    position: absolute;
    top: 12.5px;
    right: $page-side-padding;
  }
}
