@import '@grain/web-components/src/styles/colors';

.offline-markup-form-container {
  .offline-markup-form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 330px;

    .base-input-container {
      width: 180px;
      .base-field-container {
        .label-and-field {
          flex-direction: row;

          .base-input-inner-container {
            width: 70px;
          }

          .label-container {
            width: 80px;
            padding-top: 8px;
          }
        }
      }
    }
  }

  .cta-button-container {
    padding-top: 20px;
    border-top: 1px solid $border-secondary;

    .base-button.small {
      height: 35px;
    }
  }

  .form-title {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $border-secondary;
    color: $text-primary;
  }
}
