$screen-width-small-desktop: 1920;
$screen-width-tablet: 1366;
$screen-width-mobile: 768;

@mixin min-width-small-desktop {
  @media (min-width: #{$screen-width-tablet}px) {
    @content;
  }
}

@mixin max-width-small-desktop {
  @media (max-width: #{$screen-width-small-desktop - 1}px) {
    @content;
  }
}

@mixin max-width-tablet {
  @media (max-width: #{$screen-width-tablet - 1}px) {
    @content;
  }
}

@mixin max-width-mobile {
  @media (max-width: #{$screen-width-mobile - 1}px) {
    @content;
  }
}

@mixin only-tablet-width {
  @media (min-width: #{$screen-width-mobile}px) and (max-width: #{$screen-width-tablet - 1}px) {
    @content;
  }
}

@mixin min-width-tablet {
  @media (min-width: #{$screen-width-mobile}px) {
    @content;
  }
}

@mixin only-mobile {
  @media (max-width: #{$screen-width-mobile - 1}px) {
    @content;
  }
}

:export {
  smallDesktop: $screen-width-small-desktop;
  tablet: $screen-width-tablet;
  mobile: $screen-width-mobile;
}
