@import '../styles/colors.scss';

.iconic-button {
  align-items: center;
  background-color: transparent;
  border: unset;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: fit-content;

  .iconic-button-icon {
    height: 14px;
    width: 14px;
  }

  .iconic-button-text {
    color: $text-primary;
    font-size: 14px;
    line-height: 14px;
  }

  :hover {
    opacity: 0.85;
  }
}
