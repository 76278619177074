@import '../styles/colors.scss';

.chip {
  align-items: center;
  background-color: $bg-secondary;
  border-radius: 54px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  height: 30px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  width: fit-content;

  .chip-text {
    color: $text-primary;
    font-size: 14px;
    line-height: 14px;
  }

  &.chip-disabled {
    background-color: $bg-disabled;

    .chip-text {
      color: $text-disabled;
    }
  }
}
