@import '../styles/colors.scss';

.table-body {
  padding-top: 8px;
  overflow-y: scroll;
  display: block;
  height: 100%;

  .table-row {
    display: table;
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    .table-cell {
      color: $text-primary;
      font-size: 14px;
      // acts as min-height when <td>. when the content is too long, it will expand.
      height: 50px;
      line-height: 34px;
      padding-inline: 5px;

      &:first-child {
        padding-left: 10px;
      }

      &:last-child {
        padding-right: 10px;
      }
    }

    &:hover {
      background-color: $bg-primary;
      border-radius: 10px;

      .table-cell {
        &:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        &:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }

    &.table-row-clickable {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &.loading {
    opacity: 0.1;
    pointer-events: none;
  }
}
