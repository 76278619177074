@import '@grain/web-components/src/styles/colors';

.report-manual-forwards-dialog-container.base-dialog-content {
  padding: 0;
  width: 540px;
  height: 400px;

  .close-button {
    position: absolute;
    right: 20px;
    top: 15px;
  }

  .report-manual-forwards-dialog-content-container {
    height: 100%;
    width: 100%;

    .section-header {
      border-bottom: 1px solid $border-secondary;
      margin-bottom: 20px;
      padding-bottom: 5px;
    }
  }

  .upload-section-container {
    height: 100%;
  }
}
