@mixin status-colors($color, $bg-color: #{$color}1a) {
  background-color: #{$bg-color};
  color: $color;
  .status-badge-link {
    color: $color;
  }
}

.status-badge-container {
  height: 22px;
  border-radius: 3px;
  padding: 5px 7px;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  width: fit-content;

  .status-badge-link {
    display: flex;
    align-items: center;
    text-decoration-line: none;
  }

  .icon {
    svg {
      display: flex;
      width: 14px;
      height: 14px;
      margin-right: 8px;
    }
  }
}
