@import '../styles/colors';
@import '../styles/effects';
@import '../styles/breakpoints';

$gap: 24px;

.dialog-form-step-container {
  .form-title {
    color: $text-primary;
    font-weight: 500;
    font-size: 20px;
    margin-top: 5px;
    margin-bottom: 20px;
  }

  .form-container {
    width: 640px;

    form {
      display: flex;
      flex-direction: column;

      .inputs-container {
        @include blue-card-background;
        padding: 40px 20px;
        display: flex;
        flex-direction: column;
        gap: $gap;

        .two-columns {
          display: flex;
          width: 100%;
          gap: $gap;
        }
      }

      .cta-button-container {
        padding-top: 20px;
        border-top: 1px solid $border-secondary;

        .base-button.blue {
          width: 100%;
        }
      }
    }
  }

  @include max-width-mobile {
    .form-container {
      width: 100%;

      .inputs-container {
        .two-columns {
          flex-direction: column;
        }
      }
    }
  }
}
