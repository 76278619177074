@import '../styles/colors.scss';

.health-status-chip {
  .health-status-indicator {
    font-size: 24px;
    line-height: 24px;

    &.health-status-indicator-healthy {
      color: $success;
    }

    &.health-status-indicator-down {
      color: $error;
    }
  }
}
