@import '../styles/colors';

.base-table-container .base-table-wrapper .base-table tbody {
  .empty-expansion-table-row {
    display: none;
  }

  tr.expansion-table-row {
    > td {
      padding: 20px;
      background-color: $bg-primary;

      tr:nth-child(even),
      tr:nth-child(odd) {
        // css for an inner table if exists
        background-color: white;

        &:not(:first-child) {
          td {
            border-top: 1px solid $border-secondary;
            background-color: white;
          }
        }
      }
    }
  }
}
