@import '@grain/web-components/src/styles/colors';
@import '@grain/web-components/src/badges/StatusBadge';

.spot-status-badge-container {
  &.completed {
    @include status-colors($success);
  }

  &.pending {
    @include status-colors($text-disabled);
  }
}
