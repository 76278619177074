$white: #ffffff;
$almost-black: #403b3b; // TODO: remove after old tables are migrated to new tables in admin console
$orange: #ff842b;
$yellow: #fed164;
$purple: #a2a1ff;
$light-blue: #bdd4fc;
$light-grey: #a3aed0;

$cta: #3d82f7;
$error: #ff8484;
$success: #03A97E;
$linear-gradient-blue: #b3cfff;

$text-primary: #2b3674;
$text-secondary: #626B93;
$text-disabled: #93969D;

$bg-primary: #f4f7fe;
$bg-secondary: #fafcfe;
$bg-disabled: #f2f2f2;

$border-primary: #DFE3EB;
$border-secondary: #E8ECF5;

:export {
  cta: $cta;
  orange: $orange;
  yellow: $yellow;
  purple: $purple;
  almostBlack: $almost-black;
  error: $error;
  lightGrey: $light-grey;
  textPrimary: $text-primary;
  white: $white;
  bgPrimary: $bg-primary;
  success: $success;
}
