@import '../styles/colors';

.base-auto-complete-container {
  width: 100%;

  .MuiAutocomplete-option.disabled {
    color: $text-disabled;
    cursor: default;
  }

  &.highlighted {
    .base-input-container .base-input-inner-container fieldset.MuiOutlinedInput-notchedOutline {
      border: 1px solid $light-blue;
    }
  }
}
