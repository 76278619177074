@import '@grain/web-components/src/styles/colors';

.partners-page {
  .separator {
    margin: 1.5rem 0;
  }

  .table-label {
    font-size: 14px;
    color: $light-grey;
    margin-bottom: 14px;
  }

  .buttons-container {
    display: flex;
    gap: 20px;
  }
}

.partner-config-row-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
