@import '@grain/web-components/src/styles/colors';
@import '@grain/web-components/src/badges/StatusBadge';

.forwards-page-container {
  .cta-button-container {
    margin-bottom: 20px;
  }
}

.forwards-action-bar {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
