@import '../styles/colors.scss';

.select-container {
  .select-trigger {
    align-items: center;
    background-color: transparent;
    border: unset;
    cursor: pointer;
    display: inline-flex;
    gap: 7px;
    height: 32px;
    justify-content: space-between;
    line-height: 1;
    padding: 0;

    &.outlined {
      border-radius: 0.3125rem;
      border: 1px solid $border-secondary;
      padding: 0 0.5rem;
    }
  }

  .select-trigger[data-state="open"] {
    .select-icon {
      transform: rotate(180deg);
    }
  }
}

.select-content {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.12);
  margin-top: 5px;
  min-height: 16px;
  overflow: hidden;
  // https://www.radix-ui.com/primitives/docs/components/select#constrain-the-content-size
  max-height: var(--radix-select-content-available-height);

  .select-viewport {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .select-item {
      align-items: center;
      border: 0;
      border-radius: 3px;
      cursor: pointer;
      color: $text-primary;
      display: flex;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-size: 14px;
      height: 25px;
      line-height: 14px;
      padding: 0.3125rem;
      position: relative;
      user-select: none;
      vertical-align: middle;
    }

    .select-item[data-highlighted] {
      background-color: $bg-primary;
      text-decoration: none;
    }
  }

  .select-scroll-button {
    align-items: center;
    background-color: white;
    cursor: default;
    display: flex;
    height: 25px;
    justify-content: center;
  }

}
