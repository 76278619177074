@import '../styles/colors';

.icon-button {
  background-color: transparent;
  border: unset;
  display: flex;
  align-items: center;
  color: $light-grey;

  svg > path {
    stroke: $light-grey;
  }

  :hover {
    cursor: pointer;
    color: $cta;

    svg > path {
      stroke: $cta;
    }
  }
}
